import React from "react";

const EmailDisclaimerPage = () => {
  return (
    <p>
      This e-mail message and any attachments is for the exclusive use of the
      intended recipient or entity to which it is addressed and may contain
      confidential, proprietary and or legally privileged information and shall
      not attach any liability on the originator or Kaminds Nutrichem Private
      Limited and its Group Companies. Kindly note that any distribution,
      copying or use of this communication or the information in this e-mail
      message is strictly prohibited. If you have erroneously received this
      message, please notify the sender immediately and delete this message from
      your system. Kaminds Nutrichem Private Limited, its Group Companies or the
      Originator neither accept responsibility for any loss or damage arising
      from the use of this message or attachments nor guarantees the integrity
      of this communication, as e-mail transmissions are not secure and may
      suffer errors, viruses, delay, interception and amendment. Before opening
      any attachments, please recheck them for viruses and defects.
    </p>
  );
};

export default EmailDisclaimerPage;
